import { useEffect, memo, useRef } from "react";
import manager from "./manager";
import { useDispatch } from "react-redux";

import { setChildren, setTree } from "../store/app";

import Space from "../models/neo4j/Space";

import { storeJSON, getJSON } from "../utils/LocalDB";

import Broadcaster from "../helpers/Broadcaster";

import pako from "pako";

const SpaceChannel = memo(
  ({ spaceId, fetchSpace, access, updateChildren, onUpdateChildren }) => {
    const dispatch = useDispatch();

    const intervalId = useRef();
    const queue = useRef([]);
    const fetchedSpace = useRef({});
    const fetchedChildren = useRef([]);

    useEffect(() => {
      // if (!access.role){
      //   return
      // }

      if (typeof spaceId !== "string") {
        return;
      }

      if (spaceId.length === 0) {
        return;
      }

      const time =
        access.role == "manager" || access.role == "admin" ? 500 : 1000;

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      intervalId.current = setInterval(() => {
        if (!window.$waitForTemplateResponse) {
          const element = queue.current[0];
          queue.current.splice(0, 1);
          if (element) {
            execQueue({ ...element });
          }
        }
      }, time);

      const messageCallback = (data) => {
        console.log(
          `SOCKETS - > Message received to channel ${getChannelName()}: ${JSON.stringify(
            data
          )}`
        );

        addToQueue({ ...data });

        window.$connectedTo = spaceId;
      };

      const channelName = getChannelName();

      manager.subscribe(channelName, messageCallback);

      if (
        window.$connectedTo != spaceId ||
        fetchedChildren.length == 0 ||
        fetchedSpace.slug != spaceId
      ) {
        if (access.isTopSpace) {
          if (window.$lastTree != window.$fetchedTopSlug) {
            onFetchChildren(window.$fetchedTopSlug, "tree-children");
          }
        } else {
          if (window.$lastTree != window.$fetchedTopSlug) {
            onFetchChildren(window.$fetchedTopSlug, "tree");
          }

          onFetchChildren(spaceId, "children");
        }
      } else {
        console.log("SOCKETS SPACE FETCHING SKIPPED: " + spaceId);
      }

      console.log("SOCKETS SPACE COMPONENT LOADED");

      return () => {
        manager.unsubscribe(channelName);
      };
    }, [spaceId]);

    const execQueue = ({ action, id, slug }) => {
      // Exclude some events to avoid weird reloads
      if (action == "space") {
        if (
          slug == spaceId &&
          (access.role == "manager" || access.role == "admin")
        ) {
          fetchSpace();
          onFetchChildren(slug, "children", true);
        } else if (
          slug == spaceId &&
          (access.role == "member" || access.role == "editor") && (window.location.href.split("/")[5] == "list" || window.location.href.split("/")[5] == "showcase" || window.location.href.split("/")[5] == "events")
        ) {
          onFetchChildren(slug, "children", true);
        }
      } else if (action == "tree") {
        if (data.topLevelSlug == window.$fetchedTopSlug) {
          onFetchChildren(data.topSlug, action, true);
        }
      } else if (
        action == "add_member" &&
        (!window.$spaceUserInteractions ||
          (window.$spaceUserInteractions &&
            !window.$spaceUserInteractions.logged_in) ||
          (window.$spaceUserInteractions &&
            window.$spaceUserInteractions.position <= 0))
      ) {
        if (id == window.$currentUsername) {
          fetchSpace();
          onFetchChildren(slug, "children", true);
        }
      } else if (
        action == "delete_member" &&
        (!window.$spaceUserInteractions ||
          (window.$spaceUserInteractions &&
            !window.$spaceUserInteractions.logged_in) ||
          (window.$spaceUserInteractions &&
            window.$spaceUserInteractions.position <= 0))
      ) {
        if (id == window.$currentUsername) {
          fetchSpace();
          onFetchChildren(slug, "children", true);
        }
      }
    };

    const addToQueue = ({ action, id, slug }) => {
      // window.$currentRole

      for (let i = 0; i < queue.current.length; i++) {
        const element = queue.current[i];
        if (
          element.action == action &&
          element.id == id &&
          element.slug == slug
        ) {
          return;
        }
      }

      queue.current.unshift({ action, id, slug });
    };

    const getChannelName = () => {
      return `space_${spaceId}`;
    };

    const onFetchChildren = (slug = null, action = "tree", direct = false) => {
      if (direct) {
        onFetchChildrenFromAPI(slug, action);
        return;
      }
      // getJSON(`spaces`, `space_children_${slug}`, (data) => {
      //   if (data && data.space && data.space.slug) {
      //     onSetTreeOrChildren(data, action)
      //   }
      // });
      onFetchChildrenFromAPI(slug, action);
    };

    const onFetchChildrenFromAPI = (slug = null, action = "tree") => {
      slug = slug || spaceId;

      if (typeof slug === "string" && slug.length) {
        Space.onFetchChildren(
          slug,
          { topSlug: window.$fetchedTopSlug, role: access.role },
          (res) => {
            storeJSON("spaces", `space_children_${slug}`, { ...res.data });
  
            onSetTreeOrChildren(res.data, action);
          },
          (data) => {}
        );
      }
    };

    const onSetTreeOrChildren = (data, action) => {
      const space = { ...data.space };
      const children = [...decompressGzip(data.children)];

      fetchedChildren.current = [...children];
      fetchedSpace.current = [...data.space];

      for (let i = 0; i < children.length; i++) {
        if (!children[0].role) {
          children[0].role = access.role;
        }
      }

      if (!updateChildren) {
        if (onUpdateChildren) {
          onUpdateChildren({ ...space, children: children, role: access.role });
        }
        return;
      }

      if (action.indexOf("tree") >= 0) {
        window.$lastTree = space.slug;
        window.$forceTreeUpdate = true;

        dispatch(setTree({ ...space, children: children, role: access.role }));
      }
      if (action.indexOf("children") >= 0) {
        window.$spaceChildren = {
          ...space,
          children: children,
          role: access.role,
        };

        dispatch(
          setChildren({ ...space, children: children, role: access.role })
        );
      }
      console.log("NEO UPDATE 1 -------> " + space.name);
      Broadcaster.send(
        "change_sidebarTreeNode",
        document.getElementById(`sidebar_tree_node-${space.slug}`),
        { data: { space: space, children: children, role: access.role } }
      );
    };

    const decompressGzip = (compressedData) => {
      try {
        const decompressed = pako.ungzip(compressedData.data, { to: "string" });
        return JSON.parse(decompressed);
      } catch (err) {
        console.error("Error during decompression:", err);
        return null;
      }
    };

    return null;
  }
);

export default SpaceChannel;
