import React, { useState, useEffect, useRef } from "react";

import {
  createStyles,
  Text,
  ActionIcon,
  Button,
  MantineProvider,
  Group,
  Box,
  Select,
  Accordion,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Swal from "sweetalert2";
import { DateTime } from "luxon";

import useLangUser from "../../../../utils/LangUser";
import TriggerModel from "../../../../models/EngagementTrigger";
import FunctionModel from "../../../../models/EngagementFunction";
import Step from "./Step";
import AddTrigger from "./AddTrigger";
import StepIcon from "./StepIcon";

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    padding: `15px 20px 15px 5px`,
    // paddingRight: theme.spacing.xs, // to offset drag handle
    backgroundColor: "#fff",
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    width: 80,
  },

  settings: {
    color: theme.colors.gray[6],
    fontSize: 18,
    width: 30,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.colors.gray[6],
    paddingLeft: 10,
    paddingRight: 0,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  stepFooterPadding: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "spaceBetween",
  },

  stepFooterLeft: {
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterRight: {
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterAddStep: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
  },

  addStepHidden: {
    visibility: "hidden",
  },

  addStepHover: {
    "&:hover": {
      color: "#fff",
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      backgroundColor: theme.colors.sutrablue[3],
    },
  },

  bottomConnector: {
    fontSize: 20,
    color: theme.colors.gray[4],
    marginTop: -15,
    textAlign: "center",
    height: 20,
  },

  addStepButtonWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },

  addStepButtonHover: {
    "&:hover": {
      backgroundColor: theme.colors.sutrablue[3],
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      color: "#fff",
    },
  },
}));

export default function Engagement({ onUpdate, functionsType }) {
  const { classes, cx, theme } = useStyles();
  const { language, setLangUser } = useLangUser();
  const space = useSelector((state) => state.space);
  const { t, i18n } = useTranslation();
  const [triggers, setTriggers] = useState([]);
  const [addStepOpened, setAddStepOpened] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [steps, handlers] = useListState([]);
  const [selectedStep, setSelectedStep] = useState("");
  const [stepPosition, setStepPosition] = useState(null);
  const [functions, setFunctions] = useState([]);
  const [oriFunctions, setOriFunctions] = useState([]);
  const [openAddStep, setOpenAddStep] = useState(false);

  const [accordionState, setAccordionState] = useState({});

  useEffect(() => {
    onFetchFunctions();
  }, []);

  useEffect(() => {
    onFetch();
  }, [space]);

  const onFetchFunctions = () => {
    FunctionModel.onFetch(
      functionsType,
      (data) => {
        setFunctions(
          data.map((item) => ({ label: item.name, value: item.slug }))
        );
        setOriFunctions(data);
      },
      (data) => {}
    );
  };

  const onFetch = (fetchNew = false) => {
    if (!space.slug) {
      return;
    }
    TriggerModel.onFetch(
      space.slug,
      functionsType,
      (data) => {
        const auxAccordionState = {};
        for (let i = 0; i < data.length; i++) {
          auxAccordionState[i] = false;
        }
        setTriggers(data);
        handlers.setState(data);
        setTimeout(() => {
          if (fetchNew) {
            // setOpenAddStep(data[data.length-1].id)
            auxAccordionState[data.length - 1] = true;
            setAccordionState(auxAccordionState);
          } else {
            setAccordionState(auxAccordionState);
          }
        }, 500);
      },
      (data) => {}
    );
  };

  const onMigrate = () => {
    if (!space.slug) {
      return;
    }
    FunctionModel.onMigrate(
      space.slug,
      (data) => {
        onFetch(true);
        alert("Migrated");
      },
      (data) => {}
    );
  };

  return (
    <>
      {!functionsType ? (
        <>
          <Text size="sm" color="dimmed" mb={20}>
            {t(
              "spaces.settings.registration.registrationFlow.registration.description1"
            )}
          </Text>
          <Text size="lg" mb={5}>
            {t(
              "spaces.settings.registration.registrationFlow.registration.description2"
            )}
          </Text>
        </>
      ) : (
        <Text size="sm" color="dimmed" mb={20}>
          {
            "This sections allows you to create a sequence of automated notifications that are triggered based on a person’s activity in a your interactive learning space.  Here you can add triggers based on different events and configure corresponding actions."
          }
        </Text>
      )}
      <Accordion
        iconPosition="right"
        icon={<FontAwesomeIcon icon={solid("plus")} />}
        styles={{
          item: {
            border: "1px solid #dee2e6",
            marginBottom: "10px",
            borderRadius: "10px",
            "&:hover": {
              borderRadius: "10px",
            },
          },
          control: {
            "&:hover": {
              borderRadius: "10px",
            },
          },
          content: {
            paddingLeft: "5px",
          },
        }}
        multiple
        state={accordionState}
        onChange={(event) => setAccordionState(event)}
      >
        {triggers.map((trigger) => {
          return (
            <Accordion.Item label={trigger.name_with_variable}>
              <Step
                trigger={trigger}
                onUpdateTriggers={onFetch}
                functions={oriFunctions}
                functionsType={functionsType}
                openAddStep={openAddStep}
                setOpenAddStep={setOpenAddStep}
              />
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Group position="apart">
        <AddTrigger
          onUpdateTriggers={onFetch}
          functions={functions}
          oriFunctions={oriFunctions}
          functionsType={functionsType}
        />
        {window.$currentRole == "admin" && functionsType == "automation" && (
          <Box mt={25}>
            <Button color="gray" radius={"xl"} onClick={() => onMigrate(true)}>
              {"Migrate legacy"}
            </Button>
          </Box>
        )}
      </Group>
    </>
  );
}
