import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";
import InteractionModel from "../../../models/Interaction";
import SpaceModel from "../../../models/Space";
import MediaFileModel from "../../../models/MediaFile";
import MessageModel from "../../../models/Message";
import Message from "../../spaces_neo/presentations/discussion/Message";
import Highlight from "../../spaces_neo/presentations/discussion/HighlightCard";
import Reflections from "../../spaces_neo/presentations/discussion/Reflections";
import TiptapInline from "./TiptapInline";

import NotificationModel from "../../../../models/NotificationModel";

import { storeJSON } from "../../../utils/LocalDB";

import { setCookie, getCookie } from "../../../utils/Cookies";

import { select as d3Select } from "d3";
import cloud from "d3-cloud";
import showdown from "showdown";

import { wideOrNarrow } from "../../../utils/PageWidth";

import {
  Button,
  createStyles,
  Box,
  ScrollArea,
  Drawer,
  Text,
  Group,
  Tabs,
  Stack,
  Title,
  Accordion,
  Textarea,
  TextInput,
  Select,
} from "@mantine/core";

import {
  closestInteraction,
  closestBlockingInteraction,
  showNextInteraction,
  beginningOfContextForInteraction,
} from "../utils/selection";

import tinycolor from "tinycolor2";
import isDark from "../../../utils/IsDarkColor";
import isSpaceThemed from "../../../utils/IsSpaceThemed";

import { useInView } from "react-intersection-observer";

import { ActionCableContext } from "../../../components/Index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

import FunctionModel from "../../../models/EngagementFunction";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

const useStyles = createStyles((theme, { colors, props }) => ({
  drawer: {
    backgroundColor: colors.background_color,
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
  formStyle: {
    "&:focus": {
      border: `1px solid ${tinycolor(colors.background_color)
        .darken(20)
        .toString()}`,
    },
  },
  buttonStyle: {
    backgroundColor: props.node.attrs.buttonBackgroundColor,
    color: props.node.attrs.buttonTextColor,
    border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
    "&:hover": {
      backgroundColor: tinycolor(props.node.attrs.buttonBackgroundColor)
        .darken(5)
        .toString(),
    },
  },
  button1: {
    borderRadius: theme.radius.xl,
    color: tinycolor(colors.primary_button_text_color).lighten(20).toString(),
    backgroundColor: colors.primary_button_background_color,
    // border: '1px solid red',
    paddingLeft: 12,
    paddingRight: 12,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
            .lighten(3)
            .toString(),
    },
  },
  button2: {
    borderRadius: theme.radius.xl,
    color: tinycolor(colors.secondary_button_text_color).lighten(20).toString(),
    backgroundColor: colors.secondary_button_background_color,
    // border: '1px solid red',
    paddingLeft: 12,
    paddingRight: 12,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: !isSpaceThemed(colors)
        ? theme.colors.gray[1]
        : tinycolor(colors.secondary_button_background_color)
            .lighten(3)
            .toString(),
    },
  },
}));

export default function InteractiveInput(props) {
  const cable = useContext(ActionCableContext);

  const colors = useSelector((state) => state.colors);

  const { classes, cx, theme } = useStyles({ colors, props });
  const { t } = useTranslation();

  const [space, setSpace] = useState(null);
  const [messages, setMessages] = useState([]);
  const [interacted, setInteracted] = useState(false);
  const [tagging, setTagging] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showPrivate, setShowPrivate] = useState(true);
  const [aiSummary, setAiSummary] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [activeTab2, setActiveTab2] = useState(0);

  const [receiveData, setReceiveData] = useState(null);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dummyPostsEmail, setDummyPostsEmail] = useState("");
  const [dummyPostsPrompt, setDummyPostsPrompt] = useState("");
  const [triggerActionsEmail, setTriggerActionsEmail] = useState("");

  const [openedReflections, setOpenedReflections] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reflectionsTagging, setReflectionsTagging] = useState(false);
  const [askPrivacy, setAskPrivacy] = useState(false);
  const [reflectionsDrawerHeader, setReflectionsDrawerHeader] = useState(
    t("spaces.presentations.discussion.reflectionHeader")
  );

  const navigate = useNavigate();
  const params = useParams();

  const { ref, inView } = useInView({ triggerOnce: true });

  const viewport = useRef();

  const loaded = useRef();
  const content = useRef();
  const auxPos = useRef();
  const querySearchCount = useRef(0);
  const messagesCopy = useRef([]);
  const fixTimeout = useRef([]);
  const interaction = useRef(null);
  const blockingInteraction = useRef(null);

  const showNextInteractionAux = useRef(false);
  const carousel = useRef({});

  const [currentHighlight, setCurrentHighlight] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [hasOthersResponses, setHasOthersResponses] = useState(false);
  const [hasUserResponses, setHasUserResponses] = useState(false);
  const [functions, setFunctions] = useState([]);
  const [oriFunctions, setOriFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const paginator = useRef({
    per: 30,
    page: 1,
  });

  const lastConnectionAttempt = useRef({
    slug: null,
    state: null,
    channel: null,
  });

  const layout = useRef();

  useEffect(() => {
    if (!loaded.current) {
      if (props.node.attrs.id == "new") {
        createIJIfNeeded();

        return;
      }
      const interactiveInputShowAllManager = JSON.parse(
        localStorage.getItem(`interactiveInputShowAllManager`, {})
      );
      if (interactiveInputShowAllManager) {
        const show = interactiveInputShowAllManager[props.node.attrs.uid];
        // setShowMessages(show ? true : false);
      }
      if (props.node.attrs.interactiveInputPreShowAll) {
        setShowAll(true);
      }

      // if (displayToTheUser() && !props.editor.isEditable && inView){
      //   onFetch();
      //   onFetchMessages();
      //   performCableConnection();
      // }
    }
  }, [props]);

  useEffect(() => {
    if (props.node.attrs.id == "new") {
      return;
    }
    const search = window.location.search;
    const interactive_slug = new URLSearchParams(search).get(
      "interactive_slug"
    );
    if (
      (
        (inView || interactive_slug) &&
        !space &&
        (!loaded.current || props.node.attrs.forceUpdate) &&
        window.location.href.split("/")[4] == props.node.attrs.circle_id
      ) || window.$modalSpaceOpened == props.node.attrs.circle_id
    ) {
      if (!props.editor.isEditable) {

        onFetch();
        onFetchMessages();
        onFetchFunctions();
        performCableConnection();
      }

      if (!props.editor.isEditable) {
        if (interactive_slug && interactive_slug == props.node.attrs.id) {
          scrollToMe();
        }
      }
    } else {
      if (window.location.href.split("/")[4] != props.node.attrs.circle_id) {
        createIJIfNeeded();
      }
    }

    const tmpShowPrivate = getCookie(`show_private_messages`);

    setShowPrivate(tmpShowPrivate == "yes" ? true : false);
  }, [inView]);

  useEffect(() => {
    if (
      !props.editor.isEditable &&
      inView &&
      space &&
      (window.location.href.split("/")[4] == props.node.attrs.circle_id || window.$modalSpaceOpened == props.node.attrs.circle_id)
    ) {
      onFetchMessages();
    } else {
      // Check on backend if another IJ exist with same ID
    }
  }, [showAll]);

  useEffect(() => {
    if (!props.editor.isEditable) {
      const search = window.location.search;
      const pod_message_id = new URLSearchParams(search).get("pod_message_id");

      if (
        pod_message_id &&
        openMessages().length > 0 &&
        props.node.attrs.interactiveInputShowAll
      ) {
        if (querySearchCount.current == 0) {
          if (isAdvancedEngagement()) {
            onChangeTab(2);

            setTimeout(() => {
              searchForMessage(pod_message_id);
            }, 4000);
          } else {
            if (window.$spaceUserInteractions.can_manage) {
              setShowMessages(true);
            }
            setShowAll(true);
            setTimeout(() => {
              searchForMessage(pod_message_id);
            }, 3000);
          }
        } else {
          searchForMessage(pod_message_id);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (!loaded.current) {
      return;
    }

    let interactiveInputShowAllManager = JSON.parse(
      localStorage.getItem(`interactiveInputShowAllManager`, {})
    );
    if (!interactiveInputShowAllManager) {
      interactiveInputShowAllManager = {};
    }
    if (showMessages) {
      interactiveInputShowAllManager[props.node.attrs.uid] = showMessages;
    } else {
      if (interactiveInputShowAllManager[props.node.attrs.uid]) {
        delete interactiveInputShowAllManager[props.node.attrs.uid];
      }
    }
    localStorage.setItem(
      "interactiveInputShowAllManager",
      JSON.stringify(interactiveInputShowAllManager)
    );

    // const {tr} = props.editor.view.state
    // tr.setNodeMarkup(props.getPos(), undefined, {
    //   ...props.node.attrs,
    //   interactiveInputShowAllManager: showMessages
    // })
    // props.editor.view.dispatch(tr)
  }, [showMessages]);

  useEffect(() => {
    if (receiveData && !props.editor.isEditable) {
      let changes = receiveData.changes;
      if (receiveData.state != "multi") {
        changes = [receiveData];
      }

      for (var i = 0; i < changes.length; i++) {
        if (changes[i].state == "new") {
          onReceivedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "update") {
          onUpdatedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "destroy") {
          onDestroyedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "pin") {
          onPinnedMessage(changes[i].pod_message_id);
        } else if (changes[i].state == "unpin") {
          onPinnedMessage(null);
        }
      }
    }
  }, [receiveData]);

  useEffect(() => {
    if (
      aiSummary &&
      aiSummary.highlights &&
      aiSummary.highlights[currentHighlight]
    ) {
      return;
    }
    setCurrentHighlight(0);
  }, [currentHighlight]);

  useEffect(() => {}, [aiSummary]);

  useEffect(() => {
    setCookie("show_private_messages", showPrivate ? "yes" : "no", 365);
  }, [showPrivate]);

  const draw = (words) => {
    const ele = d3Select(`#wc-canvas-${props.node.attrs.id}`)
      .append("svg")
      .attr("width", layout.current.size()[0])
      .attr("height", layout.current.size()[1])
      .append("g")
      .attr(
        "transform",
        "translate(" +
          layout.current.size()[0] / 2 +
          "," +
          layout.current.size()[1] / 2 +
          ")"
      )
      .selectAll("text")
      .data(words)
      .enter()
      .append("text")
      .style("font-size", function (d) {
        return d.size + "px";
      })
      .style("font-family", "Impact")
      .style("fill", function (d) {
        return randomColor();
      })
      .attr("text-anchor", "middle")
      .attr("transform", function (d) {
        return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
      })
      .text(function (d) {
        return d.text;
      });

    // const parentEle = ele._parents[0].parentElement;

    // const newParent = document.getElementById(``)
    // if (newParent){
    //   newParent.appendChild(parentEle);
    // }
  };

  const onChangeTab = (active, tabKey) => {
    setActiveTab(active);
    setActiveTab2(0);
    if (active == 2) {
      setTimeout(() => {
        setShowMessages(true);
        setShowAll(true);
      }, 200);
    }
  };

  const onChangeTab2 = (active, tabKey) => {
    setActiveTab2(active);

    if (
      aiSummary &&
      aiSummary.wordcloud &&
      aiSummary.wordcloud.length > 0 &&
      active == 1
    ) {
      setTimeout(() => {
        const sortedWordcloud = aiSummary.wordcloud.sort(
          (a, b) => b.frequency - a.frequency
        );
        const maxValue = sortedWordcloud[0].frequency;
        const array = aiSummary.wordcloud.map(function (d) {
          const max = 85;
          const min = 15;

          let size = max * (d.frequency / maxValue);

          if (size < min) {
            size = min;
          }
          return { text: d.word, size: size };
        });

        const element = document.getElementById(
          `wc-canvas-${props.node.attrs.id}`
        );

        layout.current = cloud()
          .size([element.offsetWidth, 400])
          .words(array)
          .padding(5)
          .rotate(function () {
            return (~~(Math.random() * 6) - 3) * 30;
          })
          .font("Impact")
          .fontSize(function (d) {
            return d.size;
          })
          .on("end", draw)
          .spiral("archimedean");

        layout.current.start();
      }, 100);
    }
  };

  const randomColor = () => {
    const pastelColors = [
      "#9b59b6", // Dark pastel purple
      "#34495e", // Dark pastel blue
      "#16a085", // Dark pastel green
      "#8e44ad", // Dark pastel violet
      "#2c3e50", // Dark pastel gray
      "#27ae60", // Dark pastel green
      "#2980b9", // Dark pastel blue
      "#c0392b", // Dark pastel red
      "#e74c3c", // Dark pastel pink
      "#ecf0f1", // Dark pastel gray
      "#f39c12", // Dark pastel orange
      "#d35400", // Dark pastel orange
      "#bdc3c7", // Dark pastel gray
      "#7f8c8d", // Dark pastel gray
      "#1abc9c", // Dark pastel green
      "#3498db", // Dark pastel blue
      "#95a5a6", // Dark pastel gray
      "#e67e22", // Dark pastel orange
      "#e74c3c", // Dark pastel red
      "#bdc3c7", // Dark pastel gray
    ];
    const randomIndex = Math.floor(Math.random() * pastelColors.length);
    return pastelColors[randomIndex];
  };

  const createIJIfNeeded = () => {
    // create interactive journey space, recieve new id and parent
    // update id and parent attributes
    if (!window.$currentSpaceName) {
      return;
    }

    if (!props.editor.isEditable) {
      return;
    }

    if (fixTimeout.current) {
      clearTimeout(fixTimeout.current);
    }

    fixTimeout.current = setTimeout(() => {
      const spaceName =
        window.$currentSpaceName < 90
          ? `${window.$currentSpaceName} prompt`
          : `${window.$currentSpaceName.slice(0, 90)}... prompt`;

      SpaceModel.onCreate(
        { intention: spaceName, version: "interactive-discussion" },
        window.$currentSlug,
        (data) => {
          const { tr } = props.editor.view.state;
          tr.setNodeMarkup(props.getPos(), undefined, {
            ...props.node.attrs,
            id: data.slug,
            circle_id: window.$currentSlug,
          });
          props.editor.view.dispatch(tr);

          if (!window.$spaceUserInteractions) {
            window.$spaceUserInteractions = {
              position: 9999,
              can_manage: true,
              count: 1,
            };
          }
        },
        (data) => {}
      );
    }, 3000);
  };

  const performCableConnection = () => {
    if (lastConnectionAttempt.current.channel) {
      // lastConnectionAttempt.current.channel.unsubscribe();
    }
    lastConnectionAttempt.current.channel = cable.subscriptions.create(
      {
        channel: "PodsChannel",
        pod_slug: props.node.attrs.id,
        token: window.$currentUserAuthToken,
      },
      {
        connected: () => {
          console.log(
            "Interactive Input Channel connected: " + props.node.attrs.id
          );
        },
        disconnected: () => {},
        received: (data) => {
          console.log("Interactive Input Received data: ");
          console.log(data);
          if (data.slug == props.node.attrs.id) {
            setReceiveData(data);
            return;
          }
        },
      }
    );
  };

  const onReceivedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      props.node.attrs.id,
      (data) => {
        let addToHighlights = false;
        if (data.user.id != window.$currentUserId) {
          if (!isManager() && !showAll) {
            return;
          }
        } else {
          addToHighlights = true;
        }

        let newMessages = [...messagesCopy.current];

        if (messagesCopy.current.length == 0) {
          messagesCopy.current = [...messages];
          newMessages = [...messagesCopy.current];
        }

        let exist = false;
        for (let i = 0; i < messagesCopy.current.length; i++) {
          const msg = messagesCopy.current[i];
          if (msg.id == data.id) {
            exist = true;
            break;
          }
        }

        if (!exist) {
          newMessages.push(data);
          messagesCopy.current.push(data);
          setMessages(newMessages);
        }

        if (addToHighlights) {
          let auxAiSummary = { ...aiSummary };
          auxAiSummary.highlights.unshift(data);
          setAiSummary(auxAiSummary);
        }
      },
      (data) => {}
    );
  };

  const onUpdatedMessage = (messageId) => {
    MessageModel.onFetchById(
      messageId,
      props.node.attrs.id,
      (data) => {
        const newMessages = [...messages];

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].id == messageId) {
            newMessages[i] = data;
            if (selectedMessage && messageId == selectedMessage.id) {
              setSelectedMessage({ ...newMessages[i] });
            }
            break;
          }
        }

        setMessages(newMessages);
        messagesCopy.current = [...newMessages];

        const auxAiSummary = { ...aiSummary };

        for (let i = 0; i < auxAiSummary.highlights.length; i++) {
          if (auxAiSummary.highlights[i].id == messageId) {
            auxAiSummary.highlights[i] = data;
          }
        }

        setAiSummary(auxAiSummary);
      },
      (data) => {}
    );
  };

  const onDestroyedMessage = (messageId) => {
    const newMessages = [...messages];

    for (let i = 0; i < newMessages.length; i++) {
      if (newMessages[i].id == messageId) {
        newMessages.splice(i, 1);
        break;
      }
    }

    setMessages(newMessages);
    messagesCopy.current = [...newMessages];
  };

  const onPinnedMessage = (messageId) => {
    const newSpace = { ...space };
    newSpace.pin_message = messageId;
    // dispatch(setSpace(newSpace));
  };

  const searchForMessage = (messageId) => {
    if (querySearchCount.current > 10) {
      return;
    }

    querySearchCount.current++;

    const search = window.location.search;
    const pod_message_id = new URLSearchParams(search).get("pod_message_id");
    const reflection_id = new URLSearchParams(search).get("reflection_id");

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        querySearchCount.current = 99;
        scrollToMessage(messageId);
        if (reflection_id) {
          
          const newMessage = { ...messages[i] };
          newMessage.reflectionId = reflection_id;

          setSelectedMessage(newMessage);
          setOpenedReflections(true);
          markReflectionAsSeen(messageId);
          setReflectionsDrawerHeader(
            t("spaces.presentations.discussion.reflectionHeader")
          );
        }
        return;
      }
    }

    // onFetchMessages();
  };

  const scrollToMe = () => {
    if (props.editor.isEditable) {
      return;
    }

    setTimeout(() => {
      try {
        const search = window.location.search;
        const pod_message_id = new URLSearchParams(search).get(
          "pod_message_id"
        );

        let $scrollTo = document.getElementById(`${props.node.attrs.uid}`);

        if (!pod_message_id) {
          const firstContextNode = beginningOfContextForInteraction(
            props.editor,
            props.getPos()
          );

          if (firstContextNode) {
            $scrollTo = document.getElementById(
              `${firstContextNode.attrs.uid}`
            );

            if (!$scrollTo) {
              $scrollTo = document.querySelector(
                `[uid="${firstContextNode.attrs.uid}"]`
              );
            }
          }
        }

        const $contentScrollViewport = document
          .getElementById(`main-scroll-area`)
          .querySelectorAll("div")[0];

        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 1000);
  };

  const scrollToMessage = (messageId) => {
    return;
    if (props.editor.isEditable) {
      return;
    }
    setTimeout(() => {
      try {
        const $scrollTo = document.getElementById(`msg_${messageId}`);
        const $contentScrollViewport = document
          .getElementById(`main-scroll-area`)
          .querySelectorAll("div")[0];

        const top =
          $scrollTo.offsetTop -
          $contentScrollViewport.offsetTop +
          $contentScrollViewport.scrollTop +
          80;

        $contentScrollViewport.scrollTo({
          top: top,
          behavior: "smooth",
        });
      } catch (e) {}
    }, 1000);
  };

  const onFetch = () => {
    const ctrl = new AbortController();
    SpaceModel.onFetch(
      props.node.attrs.id,
      ["members"],
      (data) => {
        loaded.current = true;
        setSpace({
          ...data.space,
          permissions: { ...data.permissions },
          colors: { ...data.colors },
          interactions: data.interactions ? { ...data.interactions } : false,
        });
      },
      (data) => {
        // Something went wrong
      },
      ctrl.signal
    );

    // MOVE
    const ctrl2 = new AbortController();
    MessageModel.onFetchAiSummary(
      props.node.attrs.id,
      (data) => {
        setAiSummary({ ...data });
        setDummyPostsPrompt(data.dummy_posts_prompt);
      },
      (data) => {
        // Something went wrong
      },
      ctrl2.signal
    );
  };

  const onFetchMessages = () => {
    const search = window.location.search;
    const _tag = new URLSearchParams(search).get("tag");
    
    let only_current_user = false
    if (isAdvancedEngagement()){
      if (!isManager() && !props.node.attrs.interactiveInputShowAll){
        only_current_user = true
      }
      if (!isManager() && props.node.attrs.interactiveInputShowAll){
        only_current_user = !showAll
      }
      if (isManager()){
        only_current_user = !showAll
      }
    }
    else{
      if (isManager()){
        only_current_user = false
      }
      else{
        only_current_user = !showAll
      }
    }

    MessageModel.onFetch(
      props.node.attrs.id,
      {
        tag: _tag,
        only_current_user: only_current_user,
        update_last_seen: displayToTheUser(),
      },
      paginator.current.page,
      (data) => {
        console.log(`HAS OTHER RESPONSES: ${data.has_others_responses}`);
        setHasOthersResponses(data.has_others_responses);
        setHasUserResponses(data.has_user_responses);
        setCanLoadMore(paginator.current.page < data.pages);

        if (paginator.current.page == 1) {
          messagesCopy.current = [...data.data];
          setMessages([...data.data]);
        } else if (data.data.length > 0) {
          const auxData = data.data.concat(messagesCopy.current);
          messagesCopy.current = [...auxData];
          setMessages([...auxData]);
      }

        if (showNextInteractionAux.current) {
          showNextInteractionAux.current = false;
          showNextInteraction(props.editor, props.getPos());
        }
      },
      (data) => {}
    );
  };

  const onLoadMore = () => {
    paginator.current.page++;
    onFetchMessages();
  };

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xs";
    }
  };

  const displayToTheUser = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        auxPos.current = "manage";
        return true;
      }
      try {
        interaction.current = closestBlockingInteraction(
          props.editor,
          props.getPos(),
          true
        );
        if (interaction.current) {
          auxPos.current = interaction.current.interactivePosition;
        }

        if (
          interaction.current != null &&
          window.$spaceUserInteractions.position <
            interaction.current.interactivePosition
        ) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log('IJ ERROR displayToTheUser:', error);
        return false;
      }
    }
    return true;
  };

  const isActiveToTheUser = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        return true;
      }
      try {
        interaction.current = closestInteraction(
          props.editor,
          props.getPos(),
          true
        );

        blockingInteraction.current = closestBlockingInteraction(
          props.editor,
          props.getPos(),
          true
        );

        return (
          (!interaction.current &&
            !blockingInteraction.current &&
            window.$spaceUserInteractions.position == -1) ||
          (interaction.current != null &&
            window.$spaceUserInteractions.position ==
              interaction.current.interactivePosition) ||
          (blockingInteraction.current != null &&
            window.$spaceUserInteractions.position >=
              blockingInteraction.current.interactivePosition)
        );
      } catch (error) {
        console.log('IJ ERROR isActiveToTheUser:', error);
        return false;
      }
    }
    return false;
  };

  const isInteractedWith = () => {
    if (window.$spaceUserInteractions) {
      if (window.$spaceUserInteractions.can_manage) {
        return true;
      }
      try {
        return (
          window.$spaceUserInteractions.position >=
          props.node.attrs.interactivePosition
        );
      } catch (error) {
        console.log('IJ ERROR isInteractedWith:', error);
        return false;
      }
    }
    return false;
  };

  const isManager = () => {
    return (
      window.$spaceUserInteractions && window.$spaceUserInteractions.can_manage
    );
  };

  const onContentUpdate = (_content, html, clearCallback, isEmpty) => {
    if (isEmpty) {
      content.current = null;
      return;
    }

    content.current = {
      json: _content.content,
      html: html,
      clear: clearCallback,
    };
  };

  const onSetAskPrivacy = () => {
    if (!content.current) {
      return;
    }

    setAskPrivacy(true);
  };

  const onSave = (privacy = "open") => {
    if (!content.current) {
      return;
    }
    if (
      !window.$spaceUserInteractions.logged_in &&
      !window.$spaceUserInteractions.can_manage
    ) {
      localStorage.setItem("join_slug", params.slug);
      navigate(`/user/start?s=${params.slug}`);
      return;
    }
    if (props.editor && !props.editor.isEditable && !saving) {
      setSaving(true);

      setTimeout(() => {
        if (
          !content.current.json ||
          (content.current.json[0] &&
            content.current.json[0].type == "paragraph" &&
            !content.current.json[0].content)
        ) {
          setSaving(false);
          return;
        }
        const json = {
          attrs: {
            uid: `message-${new Date().getTime()}`,
          },
          type: "paragraph",
          content: content.current.json,
        };
        const html = content.current.html;

        MessageModel.onCreate(
          props.node.attrs.id,
          { json, html, privacy, auto_tag: props.node.attrs.autoTag },
          (data) => {
            setAskPrivacy(false);
            setSaving(false);
            // onFetchMessages()
            content.current.clear();
            storeJSON("spaces", `interactive-input-${props.node.attrs.id}`, {
              json: "",
              html: "",
            });

            content.current = null;

            if (window.$spaceUserInteractions.can_manage) {
              setShowMessages(true);
              setShowAll(true);
              return;
            }

            if (isAdvancedEngagement()) {
              if (props.node.attrs.interactiveInputShowAll) {
                setShowAll(true);
              }
              onSaveInterationOnly();
              setInteracted(true);
              onPromptResponseScroll();
              return;
            }

            if (!isInteractedWith()) {
              onSaveInteration();
            } else {
              if (props.node.attrs.interactiveInputShowAll) {
                setShowAll(true);
              }
            }
          },
          (data) => {
            setSaving(false);
          }
        );
      }, 550);
    }
  };

  const isAdvancedEngagement = () => {
    return props.node.attrs.interactiveAdvancedEngagement;
  };

  const onSaveInterationOnly = () => {

    InteractionModel.onCreate(
      params.slug,
      {
        view_id: `circle-content-${params.slug}`,
        position: props.node.attrs.interactivePosition,
      },
      (data) => {},
      (data) => {}
    );
  };

  const onSaveInteration = () => {
    InteractionModel.onCreate(
      params.slug,
      {
        view_id: `circle-content-${params.slug}`,
        position: props.node.attrs.interactivePosition,
      },
      (data) => {
        window.$spaceUserInteractions = {
          count: window.$spaceUserInteractions
            ? window.$spaceUserInteractions.count
            : 1,
          position: props.node.attrs.interactivePosition,
          logged_in: window.$spaceUserInteractions
            ? window.$spaceUserInteractions.logged_in
            : false,
        };

        window.$spaceUserInteractions.position =
          props.node.attrs.interactivePosition;

        showNextInteractionAux.current = true;

        if (props.node.attrs.interactiveInputShowAll) {
          setShowAll(true);
        }

        showNextInteraction(props.editor, props.getPos(), false);

        setInteracted(false);
        setInteracted(true);

        if (data.auto_joined) {
          // setTimeout(() => {
          //   navigate(`/space/${params.slug}?refresh=true`);
          // }, 1000);
        }
      },
      (data) => {}
    );
  };

  const onSkip = () => {
    if (props.editor && !props.editor.isEditable) {
      if (window.$spaceUserInteractions.can_manage) {
        return;
      }
      if (!window.$spaceUserInteractions.logged_in) {
        localStorage.setItem("join_slug", params.slug);
        navigate(`/user/start?s=${params.slug}`);

        return;
      }

      if (isAdvancedEngagement()) {
        onSaveInterationOnly();
        setShowAll(true);
        setInteracted(true);
        onPromptResponseScroll();
        return;
      }

      InteractionModel.onCreate(
        params.slug,
        {
          view_id: `circle-content-${params.slug}`,
          position: props.node.attrs.interactivePosition,
        },
        (data) => {
          window.$spaceUserInteractions = {
            count: window.$spaceUserInteractions
            ? window.$spaceUserInteractions.count
            : 1,
            position: props.node.attrs.interactivePosition,
            logged_in: window.$spaceUserInteractions
              ? window.$spaceUserInteractions.logged_in
              : false,
          };
          showNextInteraction(props.editor, props.getPos());
          setInteracted(true);
        },
        (data) => {}
      );
    }
  };

  const onOpenReflections = (messageId) => {
    setOpenedReflections(true);
    setReflectionsDrawerHeader(
      t("spaces.presentations.discussion.reflectionHeader")
    );

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].id == messageId) {
        markReflectionAsSeen(messageId);
        setSelectedMessage(messages[i]);
        return;
      }
    }

    if (aiSummary && aiSummary.highlights){
      for (let i = 0; i < aiSummary.highlights.length; i++) {
        if (aiSummary.highlights[i].id == messageId) {
          markReflectionAsSeen(messageId);
          setSelectedMessage(aiSummary.highlights[i]);
          return;
        }
      }
    }

  };

  const markReflectionAsSeen = (messageId) => {
    NotificationModel.onMarkAsSeen("reflection", messageId, (data) => {});
  };

  const openMessages = () => {
    if (isManager()) {
      if (showPrivate) {
        return messages.filter((msg) => msg.privacy == "open");
      } else {
        return messages.filter((msg) => !msg.is_private);
      }
    } else {
      return messages.filter((msg) => msg.privacy == "open");
    }
  };

  const hasPrivateMessages = () => {
    return messages.filter((msg) => msg.is_private).length > 0;
  };

  const onMoveOn = () => {

    if (!window.$spaceUserInteractions.logged_in) {
      localStorage.setItem("join_slug", params.slug);
      navigate(`/user/start?s=${params.slug}`);

      return;
    }

    if (!isInteractedWith()) {
      onSaveInteration();
    }

    setTimeout(() => {
      const _pos = props.getPos();
      const node = props.node;
      const viewport = document.querySelector(
        "#main-scroll-area .mantine-ScrollArea-viewport"
      );
      if (viewport) {
        const node = props.editor.view.state.doc.nodeAt(_pos + 1);
        let element = document.getElementById(node.attrs.uid);
        if (!element) {
          element = document.querySelector(`[uid="${node.attrs.uid}"]`);
        }
        viewport.scrollTo({ top: element.offsetTop - 50 });
      }
    }, 1000);
  };

  const onPromptResponseScroll = () => {
    setTimeout(() => {
      const _pos = props.getPos();
      const node = props.node;
      const viewport = document.querySelector(
        "#main-scroll-area .mantine-ScrollArea-viewport"
      );
      if (viewport) {
        const node = props.editor.view.state.doc.nodeAt(_pos);
        let element = document.getElementById(node.attrs.uid);
        if (!element) {
          element = document.querySelector(`[uid="${node.attrs.uid}"]`);
        }
        // viewport.scrollTo({ top: element.offsetTop });
        viewport.scrollBy(0, 130);
      }
    }, 1000);
  };

  const onHighlightsTrigger = () => {
    MessageModel.onAiSummaryTrigger(
      props.node.attrs.id,
      (data) => {
        alert("summary triggered");
      },
      (data) => {}
    );
  };

  const onContextTrigger = () => {
    MessageModel.onGenerateContext(
      props.node.attrs.id,
      (data) => {
        alert("context triggered");
      },
      (data) => {}
    );
  };

  const onTranscriptsTrigger = () => {
    const doc = props.editor.view.state.doc;

    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (node.type.name == "embed") {
        const mediaFile = {
          original_url: node.attrs.src,
          url: node.attrs.src,
          upload_type: "embed",
          slug: window.$currentSlug,
          embed_id: node.attrs.videoId,
          apify: true,
        };

        MediaFileModel.onCreate(
          mediaFile,
          (data) => {
            alert("MEDIA FILE STORED");
          },
          (data) => {}
        );
      }
    });
  };

  const onSuggestedPrompt = () => {
    MessageModel.onSuggestedPrompt(
      props.node.attrs.id,
      (data) => {
        alert("prompt triggered");
      },
      (data) => {}
    );
  };

  const onDummyPosts = () => {
    MessageModel.onDummyPosts(
      props.node.attrs.id,
      dummyPostsEmail,
      dummyPostsPrompt,
      (data) => {
        alert("dummy post generated");
      },
      (data) => {}
    );
  };

  const onTriggerActions = () => {
    MessageModel.onTriggerActions(
      props.node.attrs.id,
      (data) => {
        alert(`trigger action for ${triggerActionsEmail}`);
      },
      (data) => {}
    );
  };

  const onTriggerDispatcher = () => {
    MessageModel.onTriggerDispatcher(
      props.node.attrs.id,
      triggerActionsEmail,
      (data) => {
        alert(`trigger action for ${triggerActionsEmail}`);
      },
      (data) => {}
    );
  };

  const onTriggerAction = () => {
    MessageModel.onTriggerAction(
      props.node.attrs.id,
      triggerActionsEmail,
      selectedAction,
      (data) => {
        alert(`trigger action`);
      },
      (data) => {}
    );
  };

  const onSummaryHTML = () => {
    const converter = new showdown.Converter();
    return converter.makeHtml(aiSummary.summary);
  };

  const onGetMemberActions = () => {
    MessageModel.onGetMemberActions(
      props.node.attrs.id,
      triggerActionsEmail,
      (data) => {
        alert(data.actions);
      },
      (data) => {}
    );
  };

  const onFetchFunctions = () => {
    FunctionModel.onFetch(
      (data) => {
        setFunctions(
          data.map((item) => ({ label: item.name, value: item.slug }))
        );
        setOriFunctions(data);
      },
      (data) => {}
    );
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      spaceId={props.node.attrs.id}
      parentId={props.node.attrs.circle_id}
      ref={ref}
      style={{
        maxWidth: wideOrNarrow(),
      }}
      isActiveToTheUser={isActiveToTheUser() ? "yes" : "no"}
      isInteractedWith={isInteractedWith() ? "yes" : "no"}
      openMessages={openMessages().length}
      displayToTheUser={displayToTheUser() ? "yes" : "no"}
    >
      {displayToTheUser() && (
        <NodeViewContent
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          className={`node-content ${props.node.attrs.class} interactive-input-wrapper`}
          radius={props.node.attrs.radius}
        >
          <Box>
            {space &&
              ((openMessages().length > 0 &&
                (!isActiveToTheUser() ||
                  isInteractedWith() ||
                  interacted ||
                  props.node.attrs.interactiveInputPreShowAll)) ||
                isManager()) &&
              props.editor &&
              !props.editor.isEditable && (
                <>
                  {isAdvancedEngagement() ? (
                    <>
                      <Tabs
                        active={activeTab}
                        onTabChange={onChangeTab}
                        variant="pills"
                        position="center"
                        styles={{
                          tabControl: {
                            color: `${colors.default_text_color} !important`,
                            "&:hover": {
                              backgroundColor: `${
                                isDark(colors.sidebar_background_color)
                                  ? tinycolor(colors.sidebar_background_color)
                                      .lighten(10)
                                      .toString()
                                  : tinycolor(colors.sidebar_background_color)
                                      .darken(10)
                                      .toString()
                              } !important`,
                              color: `${colors.sidebar_text_color} !important`,
                            },
                          },
                          tabActive: {
                            backgroundColor: `${
                              isSpaceThemed(colors)
                                ? colors.sidebar_background_color
                                : tinycolor(colors.sidebar_background_color)
                                    .darken(5)
                                    .toString()
                            } !important`,
                            color: `${colors.sidebar_text_color} !important`,
                            "&:hover": {
                              backgroundColor: `${colors.sidebar_background_color} !important`,
                              color: `${colors.sidebar_text_color} !important`,
                            },
                          },
                        }}
                      >
                        <Tabs.Tab label="Highlights">
                          <Swiper
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            navigation={{
                              nextEl: ".custom-next",
                              prevEl: ".custom-prev",
                            }}
                            spaceBetween={20}
                            modules={[Navigation]}
                            className="mySwiper"
                          >
                            {space &&
                              space.slug &&
                              aiSummary.highlights &&
                              aiSummary.highlights.map((message, index) => {
                                return (
                                  <SwiperSlide>
                                    {({ isActive }) => (
                                      <Highlight
                                        key={`message_${message.id}`}
                                        spaceId={space.slug}
                                        spaceVersion={space.version}
                                        messageId={message.id}
                                        htmlId={`${message.type}_${message.id}`}
                                        messageCreatedAt={message.created_at}
                                        messageTitle={
                                          message.post
                                            ? message.post.subject
                                            : null
                                        }
                                        messageContent={
                                          message.version == "new"
                                            ? message.content
                                            : message.message_body
                                        }
                                        messageUnseenCount={
                                          message.unseen_count
                                        }
                                        tags={message.tags}
                                        reflections={message.reflections}
                                        user={message.user ? message.user : {}}
                                        onOpenReflections={onOpenReflections}
                                        version={message.version}
                                        canPin={false}
                                        canTag={
                                          message.user
                                            ? message.user.can_tag
                                            : false
                                        }
                                        canLike={
                                          message.user
                                            ? message.user.can_like
                                            : false
                                        }
                                        canReflect={message.user.can_reflect}
                                        canEdit={
                                          message.user
                                            ? message.user.can_edit
                                            : false
                                        }
                                        canDelete={
                                          message.user
                                            ? message.user.can_delete
                                            : false
                                        }
                                        onUpdated={onUpdatedMessage}
                                        type={"pod_message"}
                                        tagging={tagging}
                                        setTagging={setTagging}
                                        isPinned={
                                          space.pin_message == message.id
                                        }
                                        canSetOpen={message.can_set_open}
                                        canSetPrivate={
                                          message.can_set_private
                                          // || (message.user && message.user.can_edit)
                                        }
                                        isManager={isManager()}
                                        isPrivate={message.is_private}
                                        text={message.text}
                                        isLiked={message.is_liked}
                                        likesCount={message.likes_count}
                                        highlightIndex={index}
                                        onMoveOn={onMoveOn}
                                        currentSlideActive={isActive}
                                        isLastIndex={
                                          index ==
                                          aiSummary.highlights.length - 1
                                        }
                                      />
                                    )}
                                  </SwiperSlide>
                                );
                              })}

                            <div className="custom-prev"></div>
                            <div className="custom-next"></div>
                          </Swiper>
                        </Tabs.Tab>
                        <Tabs.Tab label="Summary">
                          <Tabs
                            active={activeTab2}
                            onTabChange={onChangeTab2}
                            variant="outline"
                            position="center"
                            styles={{
                              tabsListWrapper: {
                                borderBottom: isSpaceThemed(colors)
                                  ? `1px solid ${
                                      isDark(colors.background_color)
                                        ? tinycolor(colors.background_color)
                                            .lighten(20)
                                            .toString()
                                        : tinycolor(colors.background_color)
                                            .darken(20)
                                            .toString()
                                    } !important`
                                  : `1px solid #e9ecef !important`,
                              },
                              tabControl: {
                                color: `${colors.default_text_color} !important`,
                                "&:hover": {
                                  backgroundColor: `${
                                    isDark(colors.background_color)
                                      ? tinycolor(colors.background_color)
                                          .lighten(10)
                                          .toString()
                                      : tinycolor(colors.background_color)
                                          .darken(10)
                                          .toString()
                                  } !important`,
                                  color: `${colors.default_text_color} !important`,
                                },
                              },
                              tabActive: {
                                borderLeft: isSpaceThemed(colors)
                                  ? `1px solid ${
                                      isDark(colors.background_color)
                                        ? tinycolor(colors.background_color)
                                            .lighten(20)
                                            .toString()
                                        : tinycolor(colors.background_color)
                                            .darken(20)
                                            .toString()
                                    } !important`
                                  : `1px solid #e9ecef !important`,
                                borderTop: isSpaceThemed(colors)
                                  ? `1px solid ${
                                      isDark(colors.background_color)
                                        ? tinycolor(colors.background_color)
                                            .lighten(20)
                                            .toString()
                                        : tinycolor(colors.background_color)
                                            .darken(20)
                                            .toString()
                                    } !important`
                                  : `1px solid #e9ecef !important`,
                                borderRight: isSpaceThemed(colors)
                                  ? `1px solid ${
                                      isDark(colors.background_color)
                                        ? tinycolor(colors.background_color)
                                            .lighten(20)
                                            .toString()
                                        : tinycolor(colors.background_color)
                                            .darken(20)
                                            .toString()
                                    } !important`
                                  : `1px solid #e9ecef !important`,
                                backgroundColor: `${colors.background_color} !important`,
                                color: `${colors.default_text_color} !important`,
                                "&:hover": {
                                  backgroundColor: `${colors.background_color} !important`,
                                  color: `${colors.default_text_color} !important`,
                                },
                              },
                            }}
                          >
                            <Tabs.Tab label="Messages">
                              {aiSummary &&
                              aiSummary.summary != "none" &&
                              aiSummary.highlights &&
                              aiSummary.highlights.length > 1 ? (
                                <div
                                  className="markdown-cont"
                                  dangerouslySetInnerHTML={{
                                    __html: onSummaryHTML(),
                                  }}
                                />
                              ) : (
                                <Text
                                  mt={30}
                                  ml={40}
                                  mr={40}
                                  mb={40}
                                  color="dimmed"
                                >
                                  {
                                    "We’re still waiting for a few more responses to come in before we generate a summary."
                                  }
                                </Text>
                              )}
                            </Tabs.Tab>
                            <Tabs.Tab label="Wordcloud">
                              {aiSummary &&
                                aiSummary.highlights &&
                                aiSummary.highlights.length <= 3 && (
                                  <Text
                                    mt={30}
                                    ml={40}
                                    mr={40}
                                    mb={40}
                                    color="dimmed"
                                  >
                                    {
                                      "We’re still waiting for a few more responses to come in before we generate a wordcloud."
                                    }
                                  </Text>
                                )}
                              <div
                                style={{
                                  marginTop: "30px",
                                  marginBottom: "30px",
                                }}
                                id={`wc-canvas-${props.node.attrs.id}`}
                              ></div>
                            </Tabs.Tab>
                          </Tabs>
                        </Tabs.Tab>
                        <Tabs.Tab label="Discussion">
                          <Box>
                            <>
                              <Box sx={{ textAlign: "center" }}>
                                {hasUserResponses && (
                                  <>
                                    {hasOthersResponses &&
                                      props.node.attrs
                                        .interactiveInputShowAll &&
                                      (showAll ? (
                                        <Button
                                          color="dark"
                                          radius="xl"
                                          compact
                                          variant="outline"
                                          sx={(theme) => ({
                                            color:
                                              colors.primary_button_background_color,
                                            border: `1px solid ${colors.primary_button_background_color}`,
                                            "&:hover": {
                                              backgroundColor: tinycolor(
                                                colors.background_color
                                              )
                                                .darken(3)
                                                .toString(),
                                              // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                            },
                                          })}
                                          onClick={(event) => setShowAll(false)}
                                        >
                                          {t(
                                            "tiptap.components.interactiveInput.hideResponses"
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          color="dark"
                                          radius="xl"
                                          compact
                                          variant="outline"
                                          sx={(theme) => ({
                                            color:
                                              colors.primary_button_background_color,
                                            border: `1px solid ${colors.primary_button_background_color}`,
                                            "&:hover": {
                                              backgroundColor: tinycolor(
                                                colors.background_color
                                              )
                                                .darken(3)
                                                .toString(),
                                              // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                            },
                                          })}
                                          onClick={(event) => setShowAll(true)}
                                        >
                                          {t(
                                            "tiptap.components.interactiveInput.showResponses"
                                          )}
                                        </Button>
                                      ))}
                                  </>
                                )}

                                {isManager() &&
                                  (showMessages ? (
                                    <>
                                      {isManager() &&
                                        hasPrivateMessages() &&
                                        (showPrivate ? (
                                          <Button
                                            color="dark"
                                            radius="xl"
                                            compact
                                            variant="outline"
                                            ml={10}
                                            sx={(theme) => ({
                                              color:
                                                colors.primary_button_background_color,
                                              border: `1px solid ${colors.primary_button_background_color}`,
                                              "&:hover": {
                                                backgroundColor: tinycolor(
                                                  colors.background_color
                                                )
                                                  .darken(3)
                                                  .toString(),
                                                // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                              },
                                            })}
                                            onClick={(event) =>
                                              setShowPrivate(false)
                                            }
                                          >
                                            {t(
                                              "tiptap.components.interactiveInput.hidePrivateMessages"
                                            )}
                                          </Button>
                                        ) : (
                                          <Button
                                            color="dark"
                                            radius="xl"
                                            compact
                                            variant="outline"
                                            ml={10}
                                            sx={(theme) => ({
                                              color:
                                                colors.primary_button_background_color,
                                              border: `1px solid ${colors.primary_button_background_color}`,
                                              "&:hover": {
                                                backgroundColor: tinycolor(
                                                  colors.background_color
                                                )
                                                  .darken(3)
                                                  .toString(),
                                                // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                              },
                                            })}
                                            onClick={(event) =>
                                              setShowPrivate(true)
                                            }
                                          >
                                            {t(
                                              "tiptap.components.interactiveInput.showPrivateMessages"
                                            )}
                                          </Button>
                                        ))}
                                    </>
                                  ) : (
                                    <Button
                                      color="dark"
                                      radius="xl"
                                      compact
                                      variant="outline"
                                      sx={(theme) => ({
                                        color:
                                          colors.primary_button_background_color,
                                        border: `1px solid ${colors.primary_button_background_color}`,
                                        "&:hover": {
                                          backgroundColor: tinycolor(
                                            colors.background_color
                                          )
                                            .darken(3)
                                            .toString(),
                                          // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                        },
                                      })}
                                      onClick={(event) => setShowMessages(true)}
                                    >
                                      {t(
                                        "tiptap.components.interactiveInput.showResponses2"
                                      )}
                                    </Button>
                                  ))}
                                {canLoadMore && showMessages && (
                                  <Button
                                    color="dark"
                                    radius="xl"
                                    ml={10}
                                    compact
                                    variant="outline"
                                    sx={(theme) => ({
                                      color:
                                        colors.primary_button_background_color,
                                      border: `1px solid ${colors.primary_button_background_color}`,
                                      "&:hover": {
                                        backgroundColor: tinycolor(
                                          colors.background_color
                                        )
                                          .darken(3)
                                          .toString(),
                                        // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                      },
                                    })}
                                    onClick={(event) => onLoadMore()}
                                  >
                                    {t(
                                      "tiptap.components.interactiveInput.loadMoreResponse"
                                    )}
                                  </Button>
                                )}
                              </Box>
                              <ScrollArea
                                style={{ height: "100%" }}
                                viewportRef={viewport}
                              >
                                {(showMessages || !isManager()) &&
                                  openMessages().map((message) => {
                                    return (
                                      <Message
                                        key={`message_${message.id}`}
                                        spaceId={space.slug}
                                        spaceVersion={space.version}
                                        messageId={message.id}
                                        htmlId={`${message.type}_${message.id}`}
                                        messageCreatedAt={message.created_at}
                                        messageTitle={
                                          message.post
                                            ? message.post.subject
                                            : null
                                        }
                                        messageContent={
                                          message.version == "new"
                                            ? message.content
                                            : message.message_body
                                        }
                                        messageUnseenCount={
                                          message.unseen_count
                                        }
                                        tags={message.tags}
                                        reflections={message.reflections}
                                        user={message.user ? message.user : {}}
                                        onOpenReflections={onOpenReflections}
                                        version={message.version}
                                        canPin={false}
                                        canTag={
                                          message.user
                                            ? message.user.can_tag
                                            : false
                                        }
                                        canLike={
                                          message.user
                                            ? message.user.can_like
                                            : false
                                        }
                                        canReflect={true}
                                        canEdit={
                                          message.user
                                            ? message.user.can_edit
                                            : false
                                        }
                                        canDelete={
                                          message.user
                                            ? message.user.can_delete
                                            : false
                                        }
                                        onUpdated={onUpdatedMessage}
                                        type={"pod_message"}
                                        tagging={tagging}
                                        setTagging={setTagging}
                                        isPinned={
                                          space.pin_message == message.id
                                        }
                                        canSetOpen={message.can_set_open}
                                        canSetPrivate={
                                          message.can_set_private
                                          // || (message.user && message.user.can_edit)
                                        }
                                        isManager={isManager()}
                                        isPrivate={message.is_private}
                                        text={message.text}
                                        isLiked={message.is_liked}
                                        likesCount={message.likes_count}
                                      />
                                    );
                                  })}
                                {openMessages().length == 0 && (
                                  <Box
                                    sx={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <Text
                                      mt={30}
                                      ml={40}
                                      mr={40}
                                      mb={40}
                                      color="dimmed"
                                    >
                                      {
                                        "This discussion hasn’t received any responses yet."
                                      }
                                    </Text>
                                  </Box>
                                )}
                              </ScrollArea>
                            </>
                          </Box>
                        </Tabs.Tab>
                        {window.$currentRole == "admin" && (
                          <Tabs.Tab label="Admin">
                            <Group mb={30} mt={20}>
                              <Button
                                onClick={() => onTranscriptsTrigger()}
                                radius="xl"
                              >
                                {`Trigger Video Transcripts`}
                              </Button>
                              <Button
                                onClick={() => onContextTrigger()}
                                radius="xl"
                              >
                                {`Trigger Ai Context`}
                              </Button>
                              <Button
                                onClick={() => onHighlightsTrigger()}
                                radius="xl"
                              >
                                {`Trigger Ai Summary`}
                              </Button>

                              <Button
                                onClick={() => onSuggestedPrompt()}
                                radius="xl"
                              >
                                {`Trigger Suggested Prompt`}
                              </Button>
                            </Group>
                            <Stack>
                              {aiSummary && (
                                <Accordion multiple mb={50}>
                                  {aiSummary.prompt && (
                                    <Accordion.Item label="Suggested prompt">
                                      <Textarea minRows={6}>
                                        {JSON.stringify(aiSummary.prompt)}
                                      </Textarea>
                                    </Accordion.Item>
                                  )}

                                  <Accordion.Item label="Context">
                                    <Textarea minRows={6}>
                                      {JSON.stringify(aiSummary.context)}
                                    </Textarea>
                                  </Accordion.Item>

                                  <Accordion.Item label="Short Contexts">
                                    <Title>{"Current"}</Title>
                                    <Textarea minRows={6}>
                                      {JSON.stringify(aiSummary.short_context)}
                                    </Textarea>
                                    <Title>{"Next"}</Title>
                                    <Textarea minRows={6}>
                                      {JSON.stringify(
                                        aiSummary.next_short_context
                                      )}
                                    </Textarea>
                                  </Accordion.Item>

                                  <Accordion.Item label="Raw text">
                                    <Textarea minRows={6}>
                                      {aiSummary.raw_text}
                                    </Textarea>
                                  </Accordion.Item>

                                  <Accordion.Item label="Summary">
                                    <Textarea minRows={6}>
                                      {JSON.stringify(aiSummary.summary)}
                                    </Textarea>
                                  </Accordion.Item>

                                  <Accordion.Item label="Wordcloud">
                                    {JSON.stringify(aiSummary.wordcloud)}
                                  </Accordion.Item>

                                  <Accordion.Item label="Highlights">
                                    {JSON.stringify(aiSummary.highlights)}
                                  </Accordion.Item>

                                  <Accordion.Item label="Dummy posts">
                                    <Stack>
                                      <Textarea
                                        minRows={6}
                                        value={dummyPostsPrompt}
                                        onChange={(event) =>
                                          setDummyPostsPrompt(
                                            event.currentTarget.value
                                          )
                                        }
                                      ></Textarea>
                                      <TextInput
                                        placeholder="Write an email"
                                        label="Email of the author"
                                        value={dummyPostsEmail}
                                        onChange={(event) =>
                                          setDummyPostsEmail(
                                            event.currentTarget.value
                                          )
                                        }
                                      />
                                      <Button
                                        radius={"xl"}
                                        onClick={() => onDummyPosts()}
                                      >
                                        {"Create post"}
                                      </Button>
                                    </Stack>
                                  </Accordion.Item>

                                  <Accordion.Item label="Trigger actions">
                                    <Stack>
                                      <TextInput
                                        placeholder="Write an email"
                                        label="Email of the member"
                                        value={triggerActionsEmail}
                                        onChange={(event) =>
                                          setTriggerActionsEmail(
                                            event.currentTarget.value
                                          )
                                        }
                                      />
                                      <Group>
                                        <Button
                                          radius={"xl"}
                                          onClick={() => onTriggerActions()}
                                        >
                                          {"Trigger actions"}
                                        </Button>
                                        <Button
                                          radius={"xl"}
                                          onClick={() => onGetMemberActions()}
                                        >
                                          {"Get member actions"}
                                        </Button>
                                        <Button
                                          radius={"xl"}
                                          onClick={() => onTriggerDispatcher()}
                                        >
                                          {"Trigger dispatcher"}
                                        </Button>
                                      </Group>
                                    </Stack>
                                  </Accordion.Item>
                                  <Accordion.Item label="Trigger email">
                                    <Stack>
                                      <TextInput
                                        placeholder="Write an email"
                                        label="Email of the member"
                                        value={triggerActionsEmail}
                                        onChange={(event) =>
                                          setTriggerActionsEmail(
                                            event.currentTarget.value
                                          )
                                        }
                                      />
                                      <Select
                                        label={"Select trigger"}
                                        value={selectedFunction}
                                        onChange={setSelectedFunction}
                                        placeholder={"Select trigger"}
                                        // itemComponent={SelectItem}
                                        data={functions}
                                        maxDropdownHeight={400}
                                        radius="sm"
                                        size="md"
                                      />
                                      {selectedFunction && (
                                        <Select
                                          label={"Select action"}
                                          value={selectedAction}
                                          onChange={setSelectedAction}
                                          placeholder={"Select action"}
                                          // itemComponent={SelectItem}
                                          data={oriFunctions
                                            .filter(
                                              (item) =>
                                                item.slug == selectedFunction
                                            )[0]
                                            .actions.map((item) => ({
                                              value: item.slug,
                                              label: item.name,
                                            }))}
                                          maxDropdownHeight={400}
                                          radius="sm"
                                          size="md"
                                        />
                                      )}
                                      <Group>
                                        <Button
                                          radius={"xl"}
                                          onClick={() => onTriggerAction()}
                                        >
                                          {"Trigger action"}
                                        </Button>
                                      </Group>
                                    </Stack>
                                  </Accordion.Item>
                                </Accordion>
                              )}
                            </Stack>
                          </Tabs.Tab>
                        )}
                      </Tabs>
                      {!isInteractedWith() && !isManager() && interacted && (
                        <Group noWrap position="center" spacing={10} mt={12}>
                          <Button
                            className={classes.button1}
                            leftIcon={
                              <FontAwesomeIcon icon={solid("arrow-down")} />
                            }
                            onClick={() => onMoveOn()}
                          >
                            {"Next section"}
                          </Button>
                        </Group>
                      )}
                    </>
                  ) : (
                    <Box>
                      <>
                        <Box>
                          {hasUserResponses && (
                            <>
                              {hasOthersResponses &&
                                props.node.attrs.interactiveInputShowAll &&
                                !isManager() &&
                                (showAll ? (
                                  <Button
                                    color="dark"
                                    radius="xl"
                                    compact
                                    variant="outline"
                                    sx={(theme) => ({
                                      color:
                                        colors.primary_button_background_color,
                                      border: `1px solid ${colors.primary_button_background_color}`,
                                      "&:hover": {
                                        backgroundColor: tinycolor(
                                          colors.background_color
                                        )
                                          .darken(3)
                                          .toString(),
                                        // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                      },
                                    })}
                                    onClick={(event) => setShowAll(false)}
                                  >
                                    {t(
                                      "tiptap.components.interactiveInput.hideResponses"
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    color="dark"
                                    radius="xl"
                                    compact
                                    variant="outline"
                                    sx={(theme) => ({
                                      color:
                                        colors.primary_button_background_color,
                                      border: `1px solid ${colors.primary_button_background_color}`,
                                      "&:hover": {
                                        backgroundColor: tinycolor(
                                          colors.background_color
                                        )
                                          .darken(3)
                                          .toString(),
                                        // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                      },
                                    })}
                                    onClick={(event) => setShowAll(true)}
                                  >
                                    {t(
                                      "tiptap.components.interactiveInput.showResponses"
                                    )}
                                  </Button>
                                ))}
                            </>
                          )}
                          {isManager() &&
                            (showMessages ? (
                              <>
                                <Button
                                  color="dark"
                                  radius="xl"
                                  compact
                                  variant="outline"
                                  sx={(theme) => ({
                                    color:
                                      colors.primary_button_background_color,
                                    border: `1px solid ${colors.primary_button_background_color}`,
                                    "&:hover": {
                                      backgroundColor: tinycolor(
                                        colors.background_color
                                      )
                                        .darken(3)
                                        .toString(),
                                      // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                    },
                                  })}
                                  onClick={(event) => setShowMessages(false)}
                                >
                                  {t(
                                    "tiptap.components.interactiveInput.hideResponses2"
                                  )}
                                </Button>
                                {isManager() &&
                                  hasPrivateMessages() &&
                                  (showPrivate ? (
                                    <Button
                                      color="dark"
                                      radius="xl"
                                      compact
                                      variant="outline"
                                      ml={10}
                                      sx={(theme) => ({
                                        color:
                                          colors.primary_button_background_color,
                                        border: `1px solid ${colors.primary_button_background_color}`,
                                        "&:hover": {
                                          backgroundColor: tinycolor(
                                            colors.background_color
                                          )
                                            .darken(3)
                                            .toString(),
                                          // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                        },
                                      })}
                                      onClick={(event) => setShowPrivate(false)}
                                    >
                                      {t(
                                        "tiptap.components.interactiveInput.hidePrivateMessages"
                                      )}
                                    </Button>
                                  ) : (
                                    <Button
                                      color="dark"
                                      radius="xl"
                                      compact
                                      variant="outline"
                                      ml={10}
                                      sx={(theme) => ({
                                        color:
                                          colors.primary_button_background_color,
                                        border: `1px solid ${colors.primary_button_background_color}`,
                                        "&:hover": {
                                          backgroundColor: tinycolor(
                                            colors.background_color
                                          )
                                            .darken(3)
                                            .toString(),
                                          // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                        },
                                      })}
                                      onClick={(event) => setShowPrivate(true)}
                                    >
                                      {t(
                                        "tiptap.components.interactiveInput.showPrivateMessages"
                                      )}
                                    </Button>
                                  ))}
                              </>
                            ) : (
                              <Button
                                color="dark"
                                radius="xl"
                                compact
                                variant="outline"
                                sx={(theme) => ({
                                  color: colors.primary_button_background_color,
                                  border: `1px solid ${colors.primary_button_background_color}`,
                                  "&:hover": {
                                    backgroundColor: tinycolor(
                                      colors.background_color
                                    )
                                      .darken(3)
                                      .toString(),
                                    // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                  },
                                })}
                                onClick={(event) => setShowMessages(true)}
                              >
                                {t(
                                  "tiptap.components.interactiveInput.showResponses2"
                                )}
                              </Button>
                            ))}
                          {canLoadMore && showMessages && (
                            <Button
                              color="dark"
                              radius="xl"
                              ml={10}
                              compact
                              variant="outline"
                              sx={(theme) => ({
                                color: colors.primary_button_background_color,
                                border: `1px solid ${colors.primary_button_background_color}`,
                                "&:hover": {
                                  backgroundColor: tinycolor(
                                    colors.background_color
                                  )
                                    .darken(3)
                                    .toString(),
                                  // color: tinycolor(colors.primary_button_background_color).lighten(5).toString()
                                },
                              })}
                              onClick={(event) => onLoadMore()}
                            >
                              {t(
                                "tiptap.components.interactiveInput.loadMoreResponse"
                              )}
                            </Button>
                          )}
                        </Box>
                        <ScrollArea
                          style={{ height: "100%" }}
                          viewportRef={viewport}
                        >
                          {(showMessages || !isManager()) &&
                            openMessages().map((message) => {
                              return (
                                <Message
                                  key={`message_${message.id}`}
                                  spaceId={space.slug}
                                  spaceVersion={space.version}
                                  messageId={message.id}
                                  htmlId={`${message.type}_${message.id}`}
                                  messageCreatedAt={message.created_at}
                                  messageTitle={
                                    message.post ? message.post.subject : null
                                  }
                                  messageContent={
                                    message.version == "new"
                                      ? message.content
                                      : message.message_body
                                  }
                                  messageUnseenCount={message.unseen_count}
                                  tags={message.tags}
                                  reflections={message.reflections}
                                  user={message.user ? message.user : {}}
                                  onOpenReflections={onOpenReflections}
                                  version={message.version}
                                  canPin={false}
                                  canTag={
                                    message.user ? message.user.can_tag : false
                                  }
                                  canLike={
                                    message.user ? message.user.can_like : false
                                  }
                                  canReflect={true}
                                  canEdit={
                                    message.user ? message.user.can_edit : false
                                  }
                                  canDelete={
                                    message.user
                                      ? message.user.can_delete
                                      : false
                                  }
                                  onUpdated={onUpdatedMessage}
                                  type={"pod_message"}
                                  tagging={tagging}
                                  setTagging={setTagging}
                                  isPinned={space.pin_message == message.id}
                                  canSetOpen={message.can_set_open}
                                  canSetPrivate={
                                    message.can_set_private
                                    // || (message.user && message.user.can_edit)
                                  }
                                  isManager={isManager()}
                                  isPrivate={message.is_private}
                                  text={message.text}
                                  isLiked={message.is_liked}
                                  likesCount={message.likes_count}
                                />
                              );
                            })}
                        </ScrollArea>
                      </>
                    </Box>
                  )}
                </>
              )}
            {props.editor && props.editor.isEditable ? (
              <>
                <Box mt={10} />
                <TiptapInline
                  editable={false}
                  content={""}
                  modals={true}
                  onUpdate={onContentUpdate}
                  exclude={["formatbar"]}
                  placeholder={
                    props.node.attrs.interactiveInputPlaceholder ||
                    t("tiptap.components.interactiveInput.placeholder")
                  }
                  contextDb={`interactive-input-${props.node.attrs.id}`}
                />
                <Group position="apart" mt={10} sx={{ alignItems: "center" }}>
                  <Box>
                    <Button
                      disabled={saving}
                      radius={getRadius()}
                      sx={(theme) => ({
                        backgroundColor: props.node.attrs.buttonBackgroundColor,
                        color: props.node.attrs.buttonTextColor,
                        border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                        "&:hover": {
                          backgroundColor: tinycolor(
                            props.node.attrs.buttonBackgroundColor
                          )
                            .darken(5)
                            .toString(),
                        },
                      })}
                    >
                      {`${props.node.attrs.buttonText}`}
                    </Button>
                    {props.node.attrs.interactiveInputAllowSkip && (
                      <Button
                        ml={10}
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            colors.secondary_button_background_color,
                          color: props.node.attrs.secondary_button_text_color,
                          border: `1px solid ${colors.secondary_button_background_color}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              colors.secondary_button_background_color
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >
                        {`Skip`}
                      </Button>
                    )}
                  </Box>
                  <Text
                    color="dimmed"
                    size="sm"
                  >{`Prompt ${props.node.attrs.id}`}</Text>
                </Group>
              </>
            ) : (isActiveToTheUser() && !isInteractedWith() && !interacted) ||
              (displayToTheUser() && openMessages().length == 0) ||
              props.node.attrs.interactiveInputAllowMultipost ||
              isManager() ? (
              <>
                <Box mt={10} />
                {!askPrivacy ? (
                  <>
                    <TiptapInline
                      editable={true}
                      content={""}
                      modals={true}
                      onUpdate={onContentUpdate}
                      exclude={["formatbar"]}
                      clearCallback={true}
                      placeholder={
                        props.node.attrs.interactiveInputPlaceholder ||
                        t("tiptap.components.interactiveInput.placeholder")
                      }
                      contextDb={`interactive-input-${props.node.attrs.id}`}
                    />

                    <Box mt={10}>
                      <Button
                        disabled={saving}
                        onClick={
                          props.node.attrs.interactiveInputShowAll &&
                          props.node.attrs.interactiveInputWithPrivacyCheck
                            ? () => onSetAskPrivacy()
                            : () => onSave()
                        }
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            props.node.attrs.buttonBackgroundColor,
                          color: props.node.attrs.buttonTextColor,
                          border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              props.node.attrs.buttonBackgroundColor
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >
                        {`${props.node.attrs.buttonText}`}
                      </Button>
                      {props.node.attrs.interactiveInputAllowSkip &&
                        !isInteractedWith() && (
                          <Button
                            ml={10}
                            onClick={() => onSkip()}
                            radius={getRadius()}
                            sx={(theme) => ({
                              backgroundColor:
                                colors.secondary_button_background_color,
                              color: colors.secondary_button_text_color,
                              border: `1px solid ${colors.secondary_button_background_color}`,
                              "&:hover": {
                                backgroundColor: tinycolor(
                                  colors.secondary_button_background_color
                                )
                                  .darken(5)
                                  .toString(),
                              },
                            })}
                          >
                            {`Skip`}
                          </Button>
                        )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        borderTop: "1px solid #efefef",
                        borderBottom: "1px solid #efefef",
                        padding: "20px 0px 25px 0px",
                      }}
                    >
                      <Text weight="bold" mb={10}>
                        {t("tiptap.components.interactiveInput.text")}
                      </Text>
                      <Button
                        mr={16}
                        onClick={() => onSave("open")}
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            props.node.attrs.buttonBackgroundColor,
                          color: props.node.attrs.buttonTextColor,
                          border: `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              props.node.attrs.buttonBackgroundColor
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >{`Yes`}</Button>
                      <Button
                        onClick={() => onSave("private")}
                        radius={getRadius()}
                        sx={(theme) => ({
                          backgroundColor:
                            colors.secondary_button_background_color,
                          color: colors.secondary_button_text_color,
                          border: `1px solid ${colors.secondary_button_background_color}`,
                          "&:hover": {
                            backgroundColor: tinycolor(
                              colors.secondary_button_background_color
                            )
                              .darken(5)
                              .toString(),
                          },
                        })}
                      >{`No`}</Button>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {space && space.permissions && (
              <Drawer
                opened={openedReflections}
                onClose={() => setOpenedReflections(false)}
                title={reflectionsDrawerHeader}
                padding="xl"
                size={600}
                classNames={{
                  drawer: classes.drawer,
                  header: classes.drawerHeader,
                }}
                // styles={{
                //   drawer: {
                //     backgroundColor: "red"
                //   }
                // }}
              >
                <Reflections
                  spaceId={space.slug}
                  spaceVersion={props.spaceVersion}
                  // canReflect={space.permissions.allow_reflections}
                  canReflect={true}
                  message={selectedMessage}
                  onUpdatedMessage={onUpdatedMessage}
                  useReflectCallback={true}
                  tagging={reflectionsTagging}
                  setTagging={setReflectionsTagging}
                />
              </Drawer>
            )}
          </Box>
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
